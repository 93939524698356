import { format } from 'date-fns';

import { AddRankSuffix } from '~/components/utils/formatters';
import { getTeamOrPlayerImageUrl } from '~/domains/common/utils/getTeamOrPlayerImageUrl';

import type { PublicBaseballGame, PublicGame } from '../../services/interfaces/propsService/api';
import type { TGroupSlip } from '../../services/interfaces/propsService/parsed/slips';

import { parsePropStatus } from './parsePropStatus';

export type GenericGameState = {
  phase: string;
  inning?: number;
  outs?: number;
};

export function parseLiveGameTime(state: PublicGame['state']): GenericGameState {
  if (state?.league === 'mlb') {
    if (state.current_inning_phase === 'top' && state.outs === 3) {
      return {
        phase: 'Mid',
        inning: state.current_inning,
        outs: undefined,
      };
    }
    if (state.current_inning_phase === 'bottom' && state.outs === 3) {
      return {
        phase: 'End',
        inning: state.current_inning,
        outs: undefined,
      };
    }
    if (state.current_inning_phase === 'bottom') {
      return {
        phase: 'Bot',
        inning: state.current_inning,
        outs: state.outs,
      };
    }
    return {
      phase: 'Top',
      inning: state.current_inning,
      outs: state.outs,
    };
  }

  if (state.phase === 'overtime') {
    return { phase: `OT - ${state.clock}` };
  }

  if (state.phase === 'pregame') {
    return { phase: state.clock };
  }

  if (state?.league === 'nhl') {
    if (state.phase === 'shootout') {
      return { phase: 'SO' };
    }
    return {
      phase: `${AddRankSuffix(state.period)} ${state?.clock}`,
    };
  }

  if (state?.league === 'ncaab')
    return {
      phase: `${AddRankSuffix(state.half)} Half ${state?.clock}`,
    };
  return {
    phase: `${AddRankSuffix(state.quarter)} QTR ${state?.clock}`,
  };
}

export function getPlayerLineup(
  homeLineup: PublicBaseballGame['home']['lineup'],
  awayLineup: PublicBaseballGame['away']['lineup'],
  entityId: string
) {
  const home = homeLineup?.find((lineup) => lineup.id === entityId && lineup.inning === 0);
  const away = awayLineup?.find((lineup) => lineup.id === entityId && lineup.inning === 0);
  return home ?? away;
}

export function parseGroupSplipProps(props: TGroupSlip['props']) {
  return props.map((prop) => ({
    id: prop.id,
    name: prop.entity_name,
    playerLastName: prop.entity.player_details.last_name,
    status: parsePropStatus(prop),
    awayTeam: {
      id: prop.game.away.id,
      alias: prop.game.away.alias,
      points: prop.game.away.points ?? prop.game.away.runs,
      lineup: prop.game.away.lineup,
    },
    homeTeam: {
      id: prop.game.home.id,
      alias: prop.game.home.alias,
      points: prop.game.home.points ?? prop.game.home.runs,
      lineup: prop.game.home.lineup,
    },
    line: {
      value: prop.line,
      label: prop.type_display,
      type: prop.type,
    },
    gameId: prop.game.id,
    playerId: prop.entity.id,
    playerName: prop.entity_name,
    playerImage: getTeamOrPlayerImageUrl(prop.league, prop.entity_id),
    playerTeamId: prop.team_id,
    gameStatus: prop.game.status,
    direction: prop.direction,
    score: prop.score,
    ...(prop.game.status === 'IN_PROGRESS' ? { gameTime: parseLiveGameTime(prop.game.state) } : {}),
    position: prop.entity.player_details.position,
    startDate: `${format(prop.game.start_date, 'EEE, LLL d')} • ${format(prop.game.start_date, 'h:mm a')}`,
    ...(prop.game.league === 'mlb'
      ? {
          playerLineup: getPlayerLineup(
            prop.game.home.lineup,
            prop.game.away.lineup,
            prop.entity.id
          ),
        }
      : {}),
  }));
}

export function parseGroupSlip(slip: TGroupSlip, userId: string) {
  return {
    id: slip.id,
    rank: (() => {
      if (slip.status === 'void') {
        return '-';
      }

      return slip.tied ? `T${slip.rank}` : `${slip.rank}`;
    })(),
    props: parseGroupSplipProps(slip.props),
    name: slip.user.handle,
    avatarUrl: slip.user.avatar_url,
    isUserEntry: slip.user.id === userId,
    score: typeof slip.amount_won === 'number' && slip.status !== 'void' ? slip.score : undefined,
  };
}
