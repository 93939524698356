import type { APIUserProp } from '../../services/interfaces/propsService/api';
import type { TUserProp } from '../../services/interfaces/propsService/parsed/props';

export type UIPropStatus =
  | 'won'
  | 'lost'
  | 'push'
  | 'live'
  | 'live_winning_over'
  | 'live_losing_under'
  | 'disabled'
  | undefined;

export const parsePropStatus = (prop: TUserProp | APIUserProp): UIPropStatus => {
  if (prop.status === 'disabled') {
    return 'disabled';
  }
  if (!['SCHEDULED', 'FINALIZED', 'CANCELED'].includes(prop.game.status)) {
    if (prop.score > prop.line) {
      if (prop.direction === 'under') {
        return 'live_losing_under';
      }
      return 'live_winning_over';
    }
    return 'live';
  }
  if (!prop.final_direction) {
    return undefined;
  }
  if (prop.final_direction === 'push') {
    return 'push';
  }
  if (prop.final_direction === prop.direction) {
    return 'won';
  }
  return 'lost';
};
