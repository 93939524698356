import type {
  GetPropsFiltersPayload,
  SlipStatus,
} from '../../services/interfaces/propsService/api';

const QuickPickQueryKeys = {
  QUICK_PICKS: (filters?: GetPropsFiltersPayload) => ['quick-picks', filters].filter(Boolean),
  QUICK_PICKS_FILTERS: () => ['quick-picks-filters'],
  QUICK_PICKS_SETTINGS: (locationState: string) => ['quick-picks-settings', locationState],
  QUICK_PICKS_LOCATION_ALLOWED: () => ['quick-picks-location-allowed'],
  QUICK_PICKS_SLIPS: (include?: SlipStatus[]) => ['quick-picks-slips', include].filter(Boolean),
  QUICK_PICKS_SLIP: (id: string) => ['quick-picks-slip', id],
  QUICK_PICKS_GROUP_SLIP: (groupId: string, slipId: string) => [
    'quick-picks-group-slip',
    groupId,
    slipId,
  ],
  QUICK_PICKS_LEADERBOARD_SLIPS: (groupId: string) => ['quick-picks-leaderboard-slips', groupId],
  QUICK_PICKS_PLAYER: (id: string) => ['quick-picks-player', id],
  QUICK_PICKS_GROUP_SLIP_ENTRIES: (groupId: string) => ['quick-picks-group-slip-entries', groupId],
  CREATE_SLIP: () => ['create-slip'],
  QUICK_PICKS_BOOSTS: () => ['quick-picks-boosts'],
  VALIDATE_BOOST: () => ['validate-boost'],
  QUICK_PICKS_GET_PROPS_BY_IDS: () => ['quick-picks-get-props-by-ids'],
};

export const POLL_INTERVAL = 300_000;
export const SHORT_CACHE_TIME = 100;

export default QuickPickQueryKeys;
