import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';
import { isApiError } from '~/domains/services/utils/isApiError';
import { objectToTuples } from '~/services/utils';

import type { PropsSettings } from './interfaces/props-settings.interface';
import type {
  APIGetBoostsResponse,
  APIGetGroupSlipResponse,
  APIGetGrouSplipEntriesResponse,
  APIGetLeaderboardSlipsResponse,
  APIGetPropsResponse,
  APIGetSlipResponse,
  APIGetSlipsResponse,
  APIValidateSlipErrorResponse,
  CreateSlipPayload,
  CreateSlipSuccessResponse,
  GetPropsFiltersResponse,
  GetPropsPayload,
  LocationAllowed,
  SlipStatus,
  ValidateSlipPayload,
} from './interfaces/propsService/api';
import type {
  APIValidateBoostResponse,
  ValidateBoostPayload,
} from './interfaces/propsService/api/boosts';
import type {
  TGetPlayerCardResponse,
  TGetPropsResponse,
} from './interfaces/propsService/parsed/props';
import type {
  GetSlipsResponse,
  TGetGroupSlipResponse,
  TGetSlipResponse,
  TValidateSlipErrorResponse,
  TValidateSlipResponse,
} from './interfaces/propsService/parsed/slips';
import parsePropsResponse from './parsers/parsePropsResponse';
import { parseGroupSlipResponse } from './parsers/parseSlipGroupResponse';
import { parseSlipResponse } from './parsers/parseSlipResponse';
import { parseSlips } from './parsers/parseSlips';
import parseValidateErrorResponse from './parsers/parseValidateErrorResponse';
import { propsServiceInstance } from './props.service.instance';

const getFilters = async (disallowed: string[]): Promise<GetPropsFiltersResponse> => {
  const query = new URLSearchParams(objectToTuples({ disallowed })).toString();
  const response = await propsServiceInstance.get<GetPropsFiltersResponse>(`/filters?${query}`);
  return response.data;
};

const getProps = async (payload: GetPropsPayload): Promise<TGetPropsResponse> => {
  const query = new URLSearchParams(objectToTuples(payload)).toString();
  const response = await propsServiceInstance.get<APIGetPropsResponse>(`/props?${query}`);
  return parsePropsResponse(response.data);
};

const getPlayerCardData = async (
  id: string,
  disallowed?: string[]
): Promise<TGetPlayerCardResponse> => {
  const query = new URLSearchParams(objectToTuples({ disallowed })).toString();
  const response = await propsServiceInstance.get<TGetPlayerCardResponse>(`/props/${id}?${query}`);
  return response.data;
};

const getSettings = async (): Promise<PropsSettings> => {
  const response = await propsServiceInstance.get<PropsSettings>(`/settings`);
  return response.data;
};

/** @deprecated Use /settings with optional token instead */
const getLocationAllowed = async (): Promise<LocationAllowed> => {
  const response = await propsServiceInstance.get<LocationAllowed>(`/location_allowed`);
  return response.data;
};

/** @deprecated Use postSlip() instead */
const submitBetslip = async (): Promise<PaginatedData<PropsSettings>> => {
  const response = await propsServiceInstance.post<PaginatedData<PropsSettings>>(`/slips`);
  return response.data;
};

const getSlips = async ({
  limit,
  offset,
  include,
  parentId,
}: {
  limit: number;
  offset: number;
  include: SlipStatus[];
  parentId?: string;
}): Promise<GetSlipsResponse> => {
  const query = new URLSearchParams(
    objectToTuples({
      include,
      limit,
      offset,
      parent_id: parentId,
    })
  ).toString();
  const response = await propsServiceInstance.get<APIGetSlipsResponse>(`/slips?${query}`);
  return parseSlips(response.data);
};

const getSlip = async (slipId: string): Promise<TGetSlipResponse> => {
  const response = await propsServiceInstance.get<APIGetSlipResponse>(`/slips/${slipId}`);

  return parseSlipResponse(response.data);
};

const getGroupSlip = async (groupId: string, slipId: string): Promise<TGetGroupSlipResponse> => {
  const response = await propsServiceInstance.get<APIGetGroupSlipResponse>(
    `/groups/${groupId}/slips/${slipId}`
  );

  return parseGroupSlipResponse(response.data);
};

const cancelSlip = async (slipId: string): Promise<string> => {
  await propsServiceInstance.delete(`/slips/${slipId}`);
  return slipId;
};

const getGroupSplitEntries = async ({
  groupId,
  limit,
  offset,
}: {
  limit: number;
  offset: number;
  groupId: string;
}): Promise<APIGetGrouSplipEntriesResponse> => {
  const query = new URLSearchParams(
    objectToTuples({
      limit,
      offset,
    })
  ).toString();

  const response = await propsServiceInstance.get<APIGetGrouSplipEntriesResponse>(
    `/groups/${groupId}/entries?${query}`
  );
  return response.data;
};

const getGroupLeaderboard = async ({
  groupId,
  limit,
  offset,
}: {
  limit: number;
  offset: number;
  groupId: string;
}): Promise<APIGetLeaderboardSlipsResponse> => {
  const query = new URLSearchParams(
    objectToTuples({
      limit,
      offset,
    })
  ).toString();

  const response = await propsServiceInstance.get<APIGetLeaderboardSlipsResponse>(
    `/groups/${groupId}/slips?${query}`
  );
  return response.data;
};

const postSlip = async (
  payload: CreateSlipPayload
): Promise<CreateSlipSuccessResponse | TValidateSlipErrorResponse> => {
  try {
    const response = await propsServiceInstance.post(`/slips`, payload);

    return response.data;
  } catch (e) {
    if (
      isApiError(e) &&
      e.response.status === 422 &&
      (e.response.data as APIValidateSlipErrorResponse)?.error === 'INVALID_PROPS'
    ) {
      return parseValidateErrorResponse(e.response.data as APIValidateSlipErrorResponse);
    }

    throw e;
  }
};

const postValidateSlip = async (payload: ValidateSlipPayload): Promise<TValidateSlipResponse> => {
  try {
    const response = await propsServiceInstance.post(`/slips/validate`, payload);

    return response.data;
  } catch (e) {
    if (
      isApiError(e) &&
      e.response.status === 422 &&
      (e.response.data as APIValidateSlipErrorResponse)?.error === 'INVALID_PROPS'
    ) {
      return parseValidateErrorResponse(e.response.data as APIValidateSlipErrorResponse);
    }

    throw e;
  }
};

const getBoosts = async ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}): Promise<APIGetBoostsResponse> => {
  const query = new URLSearchParams(
    objectToTuples({
      limit,
      offset,
    })
  ).toString();
  const response = await propsServiceInstance.get(`/boosts?${query}`);

  return response.data;
};

const validateBoost = async ({
  boostId,
  payload,
}: ValidateBoostPayload): Promise<APIValidateBoostResponse> => {
  const response = await propsServiceInstance.post(`/boosts/${boostId}/validate`, payload);

  return response.data;
};

const getPropsByIds = async (ids: string, disallowed: string[]): Promise<TGetPropsResponse> => {
  const query = new URLSearchParams(
    objectToTuples({
      disallowed,
      ids,
    })
  ).toString();
  const response = await propsServiceInstance.get(`/props/by_ids?${query}`);
  return response.data;
};

export {
  cancelSlip,
  getBoosts,
  getFilters,
  getGroupLeaderboard,
  getGroupSlip,
  getGroupSplitEntries,
  getLocationAllowed,
  getPlayerCardData,
  getProps,
  getPropsByIds,
  getSettings,
  getSlip,
  getSlips,
  postSlip,
  postValidateSlip,
  submitBetslip,
  validateBoost,
};
