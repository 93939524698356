import { reportEvent } from '~/domains/analytics';

import type { GetPropsFiltersPayload } from '../services/interfaces/propsService/api';

export const reportQuickPicksFilters = (filters: GetPropsFiltersPayload) => {
  reportEvent('QuickPicks > Filter', {
    league: filters.league,
    propType: filters.type,
    gamesFiltered: filters.game,
  });
};

export const reportQuickPicksSearch = ({ resultCount }: { resultCount: number }) => {
  reportEvent('QuickPicks > Search', {
    resultCount,
  });
};

export const reportQuickPicksPlayerCardView = ({
  location,
}: {
  location: 'board' | 'entryDetail';
}) => {
  reportEvent('QuickPicks > View Player Card', {
    location,
  });
};

export const reportQuickPicksPicksView = ({
  location,
  numberOfPicks,
}: {
  location: 'board' | 'homePromo';
  numberOfPicks: number;
}) => {
  reportEvent('QuickPicks > View Picks', {
    numberOfPicks,
    location,
  });
};

export const reportQuickPicksPicksClose = ({ numberOfPicks }: { numberOfPicks: number }) => {
  reportEvent('QuickPicks > Close Picks', {
    numberOfPicks,
  });
};

export const reportQuickPicksPickMake = ({
  location,
}: {
  location: 'board' | 'playerCard' | 'homePromo';
}) => {
  reportEvent('QuickPicks > Make Pick', {
    location,
  });
};

export const reportQuickPicksPickRemove = ({
  location,
}: {
  location: 'board' | 'playerCard' | 'entryDetail' | 'homePromo';
}) => {
  reportEvent('QuickPicks > Remove Pick', {
    location,
  });
};

export const reportQuickPicksPickEdit = ({
  location,
}: {
  location: 'board' | 'playerCard' | 'entryDetail' | 'homePromo';
}) => {
  reportEvent('QuickPicks > Swap Pick Direction', {
    location,
  });
};

export const reportQuickPicksPicksChangeEntryFee = ({
  entryFee,
  numberOfPicks,
}: {
  entryFee: number;
  numberOfPicks: number;
}) => {
  reportEvent('QuickPicks > Change Entry Fee', {
    entryFee,
    numberOfPicks,
  });
};

export const reportQuickPicksEntrySubmit = ({
  entryFee,
  numberOfPicks,
}: {
  entryFee: number;
  numberOfPicks: number;
}) => {
  reportEvent('QuickPicks > Submit Entry', {
    entryFee,
    numberOfPicks,
  });
};

export const reportQuickPicksEntryCancel = ({
  entryFee,
  numberOfPicks,
}: {
  entryFee: number;
  numberOfPicks: number;
}) => {
  reportEvent('QuickPicks > Cancel Entry', {
    entryFee,
    numberOfPicks,
  });
};

export const reportQuickPicksEntryDetailsView = ({
  location,
}: {
  location: 'active' | 'final' | 'standings';
}) => {
  reportEvent('QuickPicks > View Entry Details', {
    location,
  });
};

export const reportQuickPicksPicksAlert = ({
  numberOfChanges,
  propChanged,
  propRemoved,
}: {
  numberOfChanges: number;
  propChanged: boolean;
  propRemoved: boolean;
}) => {
  reportEvent('QuickPicks > Slip Alert', {
    numberOfChanges,
    propChanged,
    propRemoved,
  });
};

export const reportQuickPicksPicksReuse = ({ numberOfPicks }: { numberOfPicks: number }) => {
  reportEvent('QuickPicks > Reuse Picks', {
    numberOfPicks,
  });
};
